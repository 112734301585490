.select-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  &.row {
    flex-direction: row;
    align-items: center;

    .select-label {
      margin-right: 10px;
      flex: 1;
    }

    .select-field {
      flex: 2;
    }

    .invalid-feedback {
      flex: 3;
      margin-left: 10px;
    }
  }

  .select-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--color-primary-dark);
  }

  .select-field {
    height: 35px;
    padding: 5px 8px;
    font-size: 14px;
    color: var(--color-placeholder-text);
    border: 1px solid lightgray;
    border-radius: 4px;
    outline: none;
    
    &:focus {
      border-color: var(--color-primary);
    }
  }

  .invalid-feedback {
    margin-top: 5px;
    font-size: 12px;
    color: var(--color-error);
  }

  &.required-indicator {
    font-size: 12px;
  }
}
